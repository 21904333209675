import { FC } from 'react';

import defaultAnimation from 'holded/modules/cms/ui/assets/lottie/heroPlayAnimation.json';
import LottiePlayer from 'holded/modules/cms/ui/shared/components/LottiePlayer';

const HeroPlay: FC<{ animation?: unknown }> = ({ animation }) => {
  return <LottiePlayer animation={animation || defaultAnimation} />;
};

export default HeroPlay;
