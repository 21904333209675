import { useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';

import { ImageMedia } from 'holded/modules/cms/domain/imageMedia';
import NextImage from 'holded/modules/cms/ui/shared/components/Image';

type Props = {
  animation: unknown;
  placeholder?: ImageMedia | undefined;
};

const DEFAULT_ANIMATION: unknown = null;

const LottiePlayer = ({ animation, placeholder }: Props) => {
  const [animationData, setAnimationData] = useState(DEFAULT_ANIMATION);

  useEffect(() => {
    setAnimationData(animation);
  });

  if (!animationData && placeholder)
    return <NextImage media={placeholder} classImg={`w-full object-cover object-left`} lazy={false} />;

  return <Lottie animationData={animationData} loop play />;
};

export default LottiePlayer;
